import logo from "./logo.svg";
import "./App.css";
import Footer from "./components/footer/Footer";

function App() {
    return (
        <div className="content">
                <img src={logo} className="App-logo" alt="logo" />
                <h1 style={{fontFamily: "Serif", margin:"0px", marginTop:"2rem"}}>
                    Auris
                </h1>
                <Footer/>
            
        </div>
    );
}

export default App;
