import styles from "./Footer.module.css";
import { EuiGlobalToastList, EuiPanel } from "@elastic/eui";
import { Row, Col, Input, Button, Form, Spin } from "antd";
import { useState } from "react";
const { TextArea } = Input;

const Footer = () => {
    const [message, setMessage] = useState({
        message: "",
        subject: "",
        email: "",
        name: "",
    });
    const [loading, setLoading] = useState(false);
    const [toasts, setToasts] = useState([]);
    const [toastsIds, setToastsIds] = useState(0);
    const addToastHandler = () => {
        const toast = {
            id: `toast${toastsIds}`,
            title: "Correo enviado correctamente",
            color: "success",
            iconType: "check",
            text: <p>Lo contactaremos proximamente en caso de ser necesario, gracias.</p>,
        };
        setToastsIds(toastsIds + 1);
        setToasts(toasts.concat(toast));
    };

    const removeToast = (removedToast) => {
        setToasts(toasts.filter((toast) => toast.id !== removedToast.id));
    };

    const sendMailRequest = async (message, user) => {
        //alert("Test");
        return await fetch("https://landing-auris.azurewebsites.net/api/mail-contact", {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({ message, user }),
        })
            .then((response) => {
                if (response === undefined) {
                    alert("couldn connect to back");
                } else {
                    if (response.ok) {
                        return response.json();
                    } else {
                        return response.json().then((data) => {
                            let errorMessage = "Error de autentificación";
                            if (data && data.error && data.error.message) {
                                errorMessage = data.error.message;
                            }
                            throw new Error(errorMessage);
                        });
                    }
                }
            })
            .then((data) => {
                return data;
            })
            .catch((err) => {
                alert(err.message);
            });
    };

    const sendMailHandler = async () => {
        const msg = message.name + "," + message.email + "," + message.message;
        setLoading(true);
        const mailResponse = await sendMailRequest(msg, message.name);
        setLoading(false);
        addToastHandler();
        console.log(mailResponse);
    };

    const updateMailHandler = (event, name) => {
        setMessage({ ...message, [name]: event.target.value });
    };
    return (
        <footer>
            <EuiPanel style={{ backgroundColor: "transparent" }} id="contactElement" name="contactElement">
                <Row className={`${styles.footerContent} `} style={{ padding: "0px" }}>
                    <Col xs={22} sm={22} md={22} lg={22}>
                        <div>
                            <p>Contactanos si tienes alguna duda</p>
                        </div>
                        <Row gutter={[8, 8]} justify="center">
                            <Form
                                name="basic"
                                layout="vertical"
                                style={{
                                    width: "70%",
                                    marginTop: "0.5rem",
                                }}
                                onFinish={sendMailHandler}
                            >
                                <Row gutter={[8, 8]}>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Form.Item
                                            label={<label>Nombre</label>}
                                            name="nombre"
                                            colon={false}
                                            rules={[
                                                {
                                                    // required: true,
                                                    message: "Por favor ingresa tu nombre",
                                                },
                                            ]}
                                        >
                                            <Input size="small" onChange={(e) => updateMailHandler(e, "name")} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Form.Item
                                            label={<label>Correo</label>}
                                            name="correo"
                                            colon={false}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Por favor ingresa tu correo",
                                                },
                                            ]}
                                        >
                                            <Input size="small" onChange={(e) => updateMailHandler(e, "email")} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    label={<label>Razones de contacto</label>}
                                    name="razones"
                                    colon={false}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Por favor ingresa las razones de contacto",
                                        },
                                    ]}
                                >
                                    <TextArea rows={4} size="small" onChange={(e) => updateMailHandler(e, "message")} />
                                </Form.Item>
                                <Row justify="center">
                                    <Col xs={18} sm={24} md={24} lg={24}>
                                        {loading ? (
                                            <Spin />
                                        ) : (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                size="small"
                                                style={{ width: "6rem", marginTop: "0.1vw" }}
                                                // onClick={sendMailHandler}
                                            >
                                                Enviar
                                            </Button>
                                        )}
                                    </Col>
                                </Row>
                            </Form>
                        </Row>
                    </Col>
                </Row>
            </EuiPanel>
            <EuiGlobalToastList toasts={toasts} dismissToast={removeToast} toastLifeTimeMs={6000} />
        </footer>
    );
};

export default Footer;
